@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");

body {
  font-family: "Cabin", sans-serif;
  background: #f0f3f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo {
  font-size: 20px;
}

.header {
  padding: 20px;
}

.header .btn {
  font-weight: 600;
  font-size: 14px;
}

.nav-link {
  font-size: 14px;
  font-weight: 600;
}

.jumbotron .btn {
  font-size: 14px;
  font-weight: 600;
}

.footer .nav-link {
  font-weight: 600;
  font-size: 13px;
  color: #a0a0a0;
}

.copyFooter {
  font-size: 13px;
  font-weight: 500;
}

.breadcrumbNav {
  --bs-breadcrumb-divider: ">";
}
.breadcrumb-item {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}
.breadcrumb-item a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
  .header .col {
    width: 100%;
  }
}

.productBox .card {
  max-height: 400px;
  height: 100%;
  min-height: 300px;
}

.productBox .card .card-img-top {
  object-fit: contain;
  height: 250px;
}
.indeximg {
  max-height: 300px;
  min-height: 300px;
  object-fit: contain;
  cursor: pointer;
}

img {
  cursor: pointer;
}

.slick-slider {
  min-height: 100%;
  margin-bottom: 30px;
}

.slick-slide{
  transform: scale(0.8);
}
.slick-center {
  transform: scale(1);
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
.card-img {
  padding: 20px;
  object-fit: contain;
}

.slick-prev:before,
.slick-next:before {
  font-size: 36px;
  opacity: 1;
  color: #ccc;
}

.carousel-control-next-icon {
  background-color: #ccc;
  border-radius: 20px;
  padding: 5px;
}

.carousel-control-prev-icon {
  background-color: #ccc;
  border-radius: 20px;
  padding: 5px;
}

.featuredCard {
  border-radius: 10px;
  box-shadow: 0 20px 25px -15px rgb(9 13 92 / 29%);
  color: #181339;
}

.card-img{
  height: 250px;
  object-fit: contain;
  width:100%;
}

.rozet{
  right:20px;
  top:20px;
}
